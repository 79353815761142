<template>
  <div class="not-found">
    <div class="container">
      <div class="not-found-inner">
        <span class="not-found-inner__label">
          {{ $t('404.label') }}
        </span>
        <h1 class="not-found-inner__code">
          404
        </h1>
        <p class="not-found-inner__text">
          {{ $t('404.text') }}
        </p>
        <base-button
          class="not-found-inner__button"
          to="/"
        >
          {{ $t('404.button') }}
        </base-button>
        <img
          class="not-found-inner__img"
          alt
          src="/assets/images/404.svg"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound404Page',

};
</script>

<style lang="scss" src="./404.scss" />
